import { Box, SxProps, Theme, Typography } from '@mui/material';
import { TAlertBannerVariant } from './types';
import React from 'react';
import ReportRoundedIcon from '@mui/icons-material/ReportRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { TxErrorType } from '../../ui-config/errorMapping';

const getVariantMixin = (variant: TAlertBannerVariant, sx?: SxProps<Theme>): SxProps<Theme> => {
  switch (variant) {
    case 'error':
      return {
        border: '1px solid rgba(199, 0, 70, 0.32)',
        background: 'rgba(199, 0, 70, 0.16)',
        color: '#FCB6C8',
        ...sx,
      };
    case 'warning':
      return {
        border: '1px solid rgba(199, 152, 0, 0.32)',
        background: 'rgba(229, 176, 0, 0.16)',
        color: '#FCECB6',
        ...sx
      };
    case 'info':
      return {
        border: '1px solid rgba(134, 97, 255, 0.32)',
        background: 'rgba(118, 77, 255, 0.16)',
        color: '#C6B6FC',
        ...sx
      };
    case 'success':
      return {
        border: '1px solid rgba(0, 240, 188, 0.32)',
        background: 'rgba(0, 199, 156, 0.16)',
        color: '#A7F6E5',
        ...sx
      };
    default:
      return {
        ...sx
      }
  }
}

export const AlertBanner = ({ sx, variant = 'info', txError, message, onClose }: {
  txError?: TxErrorType;
  variant?: TAlertBannerVariant;
  message?: React.ReactNode;
  onClose?: () => void;
  sx?: SxProps<Theme>;
}) => (
  <Box
    sx={{
      display: 'flex',
      gap: 2,
      position: 'relative',
      mt: 4,
      mb: 0,
      py: 2.5,
      px: 4,
      borderRadius: '8px !important',
      ...getVariantMixin(variant, sx),
    }}
  >
    <Box>
      {variant === 'error' && <ReportRoundedIcon sx={{ color: '#FF0548' }} />}
      {variant === 'warning' && <WarningRoundedIcon sx={{ color: '#FFC505' }} />}
      {variant === 'info' && <ErrorRoundedIcon sx={{ color: '#8061E5' }} />}
      {variant === 'success' && <CheckCircleRoundedIcon sx={{ color: '#0CB08D' }} />}
    </Box>
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, flexGrow: 1 }}>
      {txError?.error && (
        <>
          <Typography variant="description">{txError.error}</Typography>
          <Typography
            variant="description"
            sx={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() => navigator.clipboard.writeText(txError.rawError.message.toString())}
          >
            Copy the error
          </Typography>
        </>
      )}
      {!txError?.error && variant === 'error' && (
        <Typography variant="description">There was some error. Please try changing the parameters.</Typography>
      )}
      {message && (
        <Typography variant="description">{message}</Typography>
      )}
    </Box>
    {onClose && (
      <Box>
        <CloseRoundedIcon onClick={onClose} sx={{ cursor: 'pointer', mt: '-2px', mr: '-2px' }} />
      </Box>
    )}
  </Box>
);
