import { ExternalLinkIcon } from '@heroicons/react/outline';
import { Trans } from '@lingui/macro';
import { Box, Button, Link, SvgIcon, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { useModalContext } from 'src/hooks/useModal';
import { useProtocolDataContext } from 'src/hooks/useProtocolDataContext';

export type BaseSuccessTxViewProps = {
  txHash?: string;
  redirectUrl?: string;
  children: ReactNode;
};

const ExtLinkIcon = () => (
  <SvgIcon sx={{ ml: '2px', fontSize: '12px' }}>
    <ExternalLinkIcon />
  </SvgIcon>
);

export const BaseSuccessView = ({ txHash, redirectUrl, children }: BaseSuccessTxViewProps) => {
  const { close, mainTxState } = useModalContext();
  const { currentNetworkConfig } = useProtocolDataContext();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            my: 6,
            mx: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img src="/tx-success.svg" alt="" />
        </Box>

        <Typography
          variant="h2"
          sx={{ fontSize: '20px', lineHeight: '28px' }}
        >
          <Trans>Transaction Complete</Trans>
        </Typography>

        {children}
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Link
          variant="helperText"
          href={currentNetworkConfig.explorerLinkBuilder({
            tx: txHash ? txHash : mainTxState.txHash,
          })}
          sx={{
            color: '#A5A8B6',
            fontSize: '12px',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'right',
            mt: 6,
            mb: 3,
            textDecoration: 'none',
            [':hover']: {
              color: '#F1F1F3',
            }
          }}
          target="_blank"
          rel="noreferrer noopener"
        >
          <Trans>Review tx details</Trans>
          <ExtLinkIcon />
        </Link>

        <Button
          onClick={close}
          href={redirectUrl}
          variant="contained"
          size="large"
          sx={{
            color: '#F1F1F3',
            borderRadius: '8px',
            border: '1px solid rgba(255, 255, 255, 0.06)',
            backgroundColor: '#26262B',
            minHeight: '36px',
            [':hover']: {
              backgroundColor: '#31313a',
            }
          }}
        >
          <Trans>Close</Trans>
        </Button>
      </Box>
    </>
  );
};
