import { ChainId } from '@yldr/contract-helpers';
import { Trans } from '@lingui/macro';
import { Button, SxProps, Theme, Typography } from '@mui/material';
import { useWeb3Context } from 'src/libs/hooks/useWeb3Context';
import { Warning } from '../../../components/primitives/Warning';

export type ChangeNetworkWarningProps = {
  networkName: string;
  chainId: ChainId;
  sx?: SxProps<Theme>;
};

export const ChangeNetworkWarning = ({
  networkName,
  chainId,
  sx,
}: ChangeNetworkWarningProps) => {
  const { switchNetwork } = useWeb3Context();

  const handleSwitchNetwork = () => {
    switchNetwork(chainId);
  };
  return (
    <Warning
      severity="error"
      icon={false}
      sx={{
        px: 3,
        borderRadius: '8px !important',
        ['> div']: {
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 3,
        },
        ...sx,
      }}
    >
      <Typography variant="secondary13">
        <Trans>Please switch to {networkName}.</Trans>{' '}
      </Typography>
      <Button
        variant="text"
        sx={{ height: 'auto', verticalAlign: 'top', p: 1 }}
        onClick={handleSwitchNetwork}
        disableRipple
      >
        <Typography variant="secondary13">
          <Trans>Switch Network</Trans>
        </Typography>
      </Button>
    </Warning>
  );
};
